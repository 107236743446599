<template>
  <div>
    <a-modal
        ref="printModalEl"
        v-model="show"
        title="打印编码预览"
        ok-text="确认"
        cancel-text="取消"
        :keyboard="false"
        @cancel="handleCancelPrint"
        @ok="handleConfirmPrint"
    >
      <div class="preview-content">
        <VueEasyPrint ref="vueEasyPrintEl" :table-show="true" :onePageRow="onePageRow">
          <div
              v-for="(item, index) in codeData"
              :key="index"
              class="print-content"
          >
            <div class="print-name" v-if="item.barTitle">
              {{ item.barTitle.length > 15 ? item.barTitle.substring(0, 13) + '...' : item.barTitle }}
            </div>
            <div class="print-line"></div>
            <div class="short-code">
              <span>{{ item.shortCode + '-' + item.id }}</span>
            </div>
            <div v-if="item.versionName" class="font-size-16 mt-10">{{ item.versionName }}</div>
            <VueBAarCode
                class="code-box"
                :value="item.barCode"
                height="20"
                :flat="true"
                :margin-top="0"
                :text-margin="0"
                font-size="16"
                format="EAN13"
            />
            <div class="print-name" v-if="item.orderAndServicePrice">价格：￥{{ item.orderAndServicePrice }}</div>
            <div class="page-break"></div>
          </div>
        </VueEasyPrint>
      </div>
    </a-modal>
  </div>
</template>

<script>
import VueBAarCode from "vue-barcode"
import VueEasyPrint from "vue-easy-print";
export default {
  components: {VueBAarCode, VueEasyPrint},
  data() {
    return {
      show: false,
      codeData: '',
      printWidth: '',
      printHeight: '',
      onePageRow: 1,
    };
  },
  created() {
    this.computedDPI()
  },
  methods: {
    handleEnterKey(event) {
      if (event.key === 'Enter') {
        this.handleConfirmPrint();
      }
    },
    /** 计算像素 */
    computedDPI() {
      this.printWidth = 80
      this.printHeight = 50
      console.log(this.printHeight, this.printWidth)
    },
    async showPopup(data) {
      document.removeEventListener('keyup', this.handleEnterKey)
      document.addEventListener('keyup', this.handleEnterKey);
      this.codeData = data
      this.show = true
    },
    /** 确定打印 */
    handleConfirmPrint() {
      this.$refs.vueEasyPrintEl.print()
    },
    /** 取消打印 */
    handleCancelPrint() {
      document.removeEventListener('keyup', this.handleEnterKey)
      this.destroyAll()
      this.show = false
      this.codeData = ''
      this.$emit('closeModal')
    },
    /** 销毁全部弹窗 */
    destroyAll() {
      this.$refs.printModalEl.$destroyAll();
    },
  },
};
</script>

<style lang="scss" scoped>

@media print {
  @page {
    size: auto;
  }
  .print-content {
    text-align: start;
  }
  .page-break {
    page-break-after: always;
  }
  .noprint {
    display: none;
  }
}
.preview-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.print-content {
  .print-name {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #2C2C2C;
  }
  //.print-line {
  //  width: 100%;
  //  height: 2px;
  //  background: #E2E2E2;
  //}
  .short-code {
    margin-left: 4px;
    font-size: 20px;
    font-weight: bold;
    color: #2C2C2C;
  }
}
.font-size-12 {
  font-size: 12px;
}
.font-size-16 {
  font-size: 15px;
  font-weight: bold;
  color: #2C2C2C;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: 1000,
            keyboard: false,
            title: "质检鉴定",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.params, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-item",
                { attrs: { label: "送评分类" } },
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.computedRateServicePayTypeTextByDesc(
                                _vm.params.orderRatingServiceRank
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "fs-20",
                          class:
                            _vm.dqCheckPayClassType[
                              _vm.params.orderRatingServiceRank
                            ]
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.computedRateServicePayTypeText(
                                _vm.params.orderRatingServiceRank
                              )
                            )
                          )
                        ]
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _vm.params.productRemark
                ? _c("a-form-item", { attrs: { label: "卖家描述瑕疵" } }, [
                    _c("div", { staticClass: "color-red ml-5" }, [
                      _vm._v(_vm._s(_vm.params.productRemark))
                    ])
                  ])
                : _vm._e(),
              _c("a-form-item", { attrs: { label: "是否进行标版" } }, [
                _c("div", [
                  _c(
                    "span",
                    {
                      class:
                        _vm.dqCheckVersionStatusClassType[
                          _vm.params.duibanService
                        ]
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.computedVersionStatusTypeText(
                            _vm.params.duibanService
                          )
                        )
                      )
                    ]
                  )
                ])
              ]),
              _c(
                "a-form-item",
                { attrs: { label: "质检状态" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { margin: "5px", width: "180px" },
                      attrs: {
                        dropdownMatchSelectWidth: false,
                        "filter-option": _vm.untils.filterOption,
                        dropdownMenuStyle: { "max-height": "350px" },
                        allowClear: "",
                        showSearch: "",
                        placeholder: "请选择质检状态"
                      },
                      model: {
                        value: _vm.params.reviewStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "reviewStatus", $$v)
                        },
                        expression: "params.reviewStatus"
                      }
                    },
                    _vm._l(
                      _vm.computedCheckStatusTypeList(
                        _vm.params.orderRatingServiceRank
                      ),
                      function(items) {
                        return _c(
                          "a-select-option",
                          { key: items.value, attrs: { value: items.value } },
                          [_vm._v(_vm._s(items.name))]
                        )
                      }
                    ),
                    1
                  )
                ],
                1
              ),
              _vm.params.reviewStatus === 10
                ? _c(
                    "a-form-item",
                    { attrs: { label: "质检结果" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { margin: "5px", width: "180px" },
                          attrs: {
                            dropdownMatchSelectWidth: false,
                            "filter-option": _vm.untils.filterOption,
                            dropdownMenuStyle: { "max-height": "350px" },
                            allowClear: "",
                            showSearch: "",
                            placeholder: "请选择质检结果"
                          },
                          model: {
                            value: _vm.params.responsibilityDetermine,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.params,
                                "responsibilityDetermine",
                                $$v
                              )
                            },
                            expression: "params.responsibilityDetermine"
                          }
                        },
                        _vm._l(
                          _vm.computedCheckResultTypeList(
                            _vm.params.orderRatingServiceRank
                          ),
                          function(items) {
                            return _c(
                              "a-select-option",
                              {
                                key: items.value,
                                attrs: { value: items.value }
                              },
                              [_vm._v(_vm._s(items.name))]
                            )
                          }
                        ),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.params.duibanService
                ? _c(
                    "a-form-item",
                    { attrs: { label: "标注版别-可选" } },
                    [
                      _c("a-input", {
                        staticClass: "w-250",
                        attrs: { allowClear: "", placeholder: "标注版别-可选" },
                        model: {
                          value: _vm.params.versionName,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "versionName", $$v)
                          },
                          expression: "params.versionName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                { attrs: { label: "质检图片" } },
                [
                  _c(
                    "a-upload",
                    {
                      key: _vm.imgKey,
                      attrs: {
                        data: { rotate: 180 },
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card",
                        "file-list": _vm.coverList,
                        multiple: true
                      },
                      on: {
                        preview: _vm.handlePreview,
                        change: _vm.handleChangeCoverList
                      }
                    },
                    [
                      _vm.coverList.length < 15
                        ? _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v("上传图片")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "质检描述" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "质检描述", rows: 4 },
                    model: {
                      value: _vm.params.reviewRemarks,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "reviewRemarks", $$v)
                      },
                      expression: "params.reviewRemarks"
                    }
                  }),
                  _c("div", { staticClass: "color-red" }, [
                    _vm._v("*填写之后买卖双方都将展示，请谨慎填写")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
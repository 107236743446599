var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        scopedSlots: _vm._u([
          {
            key: "itemLogisticsCompanyAboutSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a-tooltip",
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.computedRateServicePayTypeTextByDesc(
                                  row.orderRatingServiceRank
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "span",
                          {
                            class:
                              _vm.dqCheckPayClassType[
                                row.orderRatingServiceRank
                              ]
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.computedRateServicePayTypeText(
                                  row.orderRatingServiceRank
                                )
                              )
                            )
                          ]
                        )
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c("div", [_vm._v(_vm._s(row.expressNo))]),
                row.ratingUserId
                  ? _c("div", [
                      _vm._v("送评人："),
                      _c("span", { staticClass: "color-green" }, [
                        _vm._v(
                          _vm._s(
                            (row.ratingUserName || "") +
                              "【" +
                              row.ratingUserId +
                              "】"
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                row.orderRatingServiceRank === "D"
                  ? _c("div", [
                      _vm._v("D类送评数量："),
                      _c("span", [_vm._v(_vm._s(row.coinAmount) + "件")])
                    ])
                  : _vm._e(),
                _c("div", [_vm._v(_vm._s(row.logisticsCompanyName))])
              ])
            }
          },
          {
            key: "itemProductNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", [
                  _vm._v(
                    _vm._s(
                      (row.productName || "斗泉渠道代理") +
                        (row.versionName ? row.versionName : "")
                    )
                  )
                ])
              ])
            }
          },
          {
            key: "itemAuctionImageSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.productImageList
                  ? _c(
                      "div",
                      { staticClass: "img-list" },
                      _vm._l(row.productImageList, function(item, index) {
                        return _c("img", {
                          key: index,
                          attrs: {
                            src: _vm.computedNotShowImage(item),
                            alt: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.handlePreviewImg(
                                row.productImageList,
                                index
                              )
                            }
                          }
                        })
                      }),
                      0
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemCheckAuctionImageSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.items
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          attrs: { id: "DTypeMoreImageId", href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.handleShowMoreCheckImages(row.items)
                            }
                          }
                        },
                        [_vm._v("查看更多质检图片")]
                      )
                    ])
                  : _vm._e(),
                row.confirmReceiptImage
                  ? _c(
                      "div",
                      { staticClass: "img-list" },
                      _vm._l(row.confirmReceiptImage, function(item, index) {
                        return _c("img", {
                          key: index,
                          attrs: {
                            src: _vm.computedNotShowImage(item),
                            alt: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.handlePreviewImg(
                                row.confirmReceiptImage,
                                index
                              )
                            }
                          }
                        })
                      }),
                      0
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemOrderNoSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.orderRatingServiceRank === "D"
                  ? _c("span", { staticClass: "color-blue" }, [
                      _vm._v(_vm._s(row.orderNo))
                    ])
                  : _c(
                      "span",
                      {
                        staticClass: "cur-pot color-blue",
                        on: {
                          click: function($event) {
                            return _vm.handleGoToOrder(row.orderNo)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.orderNo))]
                    )
              ])
            }
          },
          {
            key: "itemBuyerNeedOrNotNeedSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.orderRatingServiceRank === "D"
                  ? _c("span", [_vm._v("-")])
                  : _c(
                      "span",
                      {
                        class:
                          _vm.dqCheckResultAfterByBuyerClassType[
                            row.buyerSelectionAfterInitialReview
                          ]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.computedResultAfterByBuyerTypeText(
                              row.buyerSelectionAfterInitialReview
                            ) || "未选择"
                          )
                        )
                      ]
                    )
              ])
            }
          },
          {
            key: "itemCheckStatusSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  { class: _vm.dqCheckStatusClassType[row.reviewStatus] },
                  [_vm._v(_vm._s(_vm.computedStatusTypeText(row.reviewStatus)))]
                )
              ])
            }
          },
          {
            key: "itemIsVersionCheckSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    class: _vm.dqCheckVersionStatusClassType[row.duibanService]
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedVersionStatusTypeText(row.duibanService)
                      )
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemCheckResultSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    class:
                      _vm.dqCheckResultClassType[row.responsibilityDetermine]
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedResultTypeText(row.responsibilityDetermine)
                      )
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemReviewRemarksSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(" " + _vm._s(row.reviewRemarks) + " ")
                      ]),
                      _c("span", { staticClass: "color-blue cur-pot" }, [
                        _vm._v(_vm._s(row.reviewRemarks.slice(0, 11) + "..."))
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "button-list" }, [
                  _c(
                    "button",
                    {
                      staticClass: "bg-blue",
                      attrs: { id: "showPushButtonId" },
                      on: {
                        click: function($event) {
                          return _vm.handleShowPush(row)
                        }
                      }
                    },
                    [_vm._v("质检审核")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "bg-cadetblue",
                      staticStyle: { "margin-top": "10px" },
                      attrs: { id: "showPrintButtonId" },
                      on: {
                        click: function($event) {
                          return _vm.handleShowPrintCode(row)
                        }
                      }
                    },
                    [_vm._v("打印条码")]
                  )
                ])
              ])
            }
          }
        ])
      }),
      _c("PushResultPopup", {
        ref: "pushResultPopupEl",
        on: {
          success: _vm.handleChangeSuccess,
          closeModal: _vm.handleCloseModal
        }
      }),
      _c("DPushResultPopup", {
        ref: "DPushResultPopupEl",
        on: {
          success: _vm.handleChangeSuccess,
          closeModal: _vm.handleCloseModal
        }
      }),
      _c("PrintCodePopup", {
        ref: "printCodePopupEl",
        on: { closeModal: _vm.handleCloseModal }
      }),
      _c("ShowMoreImages", {
        ref: "showMoreImagesEl",
        on: { closeModal: _vm.handleCloseModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
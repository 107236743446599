
/** 列表 */
export const dqCheckResultTableColumns = [
    // { title: "送评类型", align: "center", scopedSlots: {customRender: 'itemOrderRateServicePayTypeSlot'}, width: 150 },
    { title: "物流单号", align: "center", scopedSlots: {customRender: 'itemLogisticsCompanyAboutSlot'}, width: 200 },
    { title: "店铺名称", align: "center", dataIndex: 'shopName' },
    { title: "拍品名称", align: "center", scopedSlots: {customRender: 'itemProductNameSlot'} },
    { title: "拍品图片", align: "center", scopedSlots: { customRender: "itemAuctionImageSlot" }, width: 250 },
    { title: "质检图片", align: "center", scopedSlots: { customRender: "itemCheckAuctionImageSlot" }, width: 250 },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 200 },
    { title: "买家是否需要", align: "center", scopedSlots: { customRender: "itemBuyerNeedOrNotNeedSlot" } },
    { title: "订单号/D类申请单号", align: "center", scopedSlots: { customRender: "itemOrderNoSlot" }, width: 200 },
    { title: "质检状态", align: "center", scopedSlots: { customRender: "itemCheckStatusSlot" }},
    { title: "是否标版", align: "center", scopedSlots: { customRender: "itemIsVersionCheckSlot" }},
    { title: "质检结果", align: "center", scopedSlots: { customRender: "itemCheckResultSlot" }},
    { title: "结果补充", align: "center", dataIndex: 'itemReviewRemarksSlot'},
    { title: "操作时间", align: "center", dataIndex: 'createTime'},
    { title: "操作人", align: "center", dataIndex: 'sysAdmin' },
]

